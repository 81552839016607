import config from '../config';
import { FieldTypes } from 'ui-component/RHFFormField';
import { PaymentTypes } from './options';
import dayjs from 'dayjs';
import { maxDateCompare } from './date-validate';
import { States } from 'utils/options';

const {
  EXTERNAL_URLS: { BUSINESS_VALUATIONS }
} = config;

export const Categories = {
  InvestmentAssets: {
    TaxableAccount: {
      name: 'Taxable Account',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        custodian_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        // account_location: { type: FieldTypes.Select, required: true, options: 'AccountLocations' },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        interest_rate: {
          type: FieldTypes.Percentage,
          required: true
        },
        // investment_vehicle: { type: FieldTypes.Select, required: true, options: 'InvestmentVehicles' },
        yearly_contribution: { type: FieldTypes.Currency },
        // withdrawal_date: {
        //   type: FieldTypes.Date,
        //   required: true
        // },
        management_fee: {
          label: 'Advisory Fee',
          type: FieldTypes.Percentage,
          required: true
        },
        investment_manager_1: { label: 'Investment Advisor (If not us)', type: FieldTypes.Text },
        asset_class_settings: {
          type: FieldTypes.ListMap,
          options: 'AssetClasses',
          schema: (yup) =>
            yup.test('sum', 'The sum of percentages cannot be greater than 100%', (value) => {
              const sum = value ? value.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0;
              return sum <= 100;
            })
        }
      }
    },
    RetirementAccount: {
      name: 'Retirement Account',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        custodian_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        interest_rate: {
          type: FieldTypes.Percentage,
          required: true
        },
        account_type: { type: FieldTypes.Select, required: true, options: 'RetirementAccountTypes' },
        yearly_contribution: { type: FieldTypes.Currency },
        withdrawal_date: {
          type: FieldTypes.Date,
          required: true
        },
        advisory_fee: {
          type: FieldTypes.Percentage,
          required: true
        },
        investment_advisor: { label: 'Investment Advisor (If not us)', type: FieldTypes.Text },
        asset_class_settings: {
          type: FieldTypes.ListMap,
          options: 'AssetClasses',
          schema: (yup) =>
            yup.test('sum', 'The sum of percentages cannot be greater than 100%', (value) => {
              const sum = value ? value.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0;
              return sum <= 100;
            })
        }
      }
    },
    Securities: {
      name: 'Securities',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      btnText: "Find Today's Stock Price",
      btnLink: 'http://finance.yahoo.com/',
      fields: {
        brokerage_account: {
          label: 'Taxable Account',
          type: FieldTypes.Select,
          dynamic: true,
          options: 'TaxableAndSavingsAccounts',
          info: 'In order to populate this field, please create a Taxable Account entry within the Financial Assets bucket.'
        },
        symbol: { label: 'ISIN / Ticker Symbol', type: FieldTypes.Text },
        fund_name: { type: FieldTypes.Text, required: true },
        growth_rate: { label: 'Override Growth Rate', type: FieldTypes.Percentage },
        shares: { label: 'Number of Shares', type: FieldTypes.Numeric, thousandSeparator: false, required: true },
        share_price: { label: 'Amount per Share', type: FieldTypes.Currency, required: true },
        total_value: {
          type: FieldTypes.Currency,
          calculate: 'CommonTotalAmount',
          dependencies: ['shares', 'share_price'],
          total: true
        },
        asset_class: { type: FieldTypes.Select, options: 'AssetClasses' }
      }
    },
    LongTermCertificatesOfDeposit: {
      name: 'Long Term Certificates of Deposit',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        bank_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        opening_deposit: { type: FieldTypes.Currency, required: true },
        interest_payments: { type: FieldTypes.Select, required: true, options: 'MAMFrequencies' },
        interest_rate: { type: FieldTypes.Percentage, required: true },
        apy: { label: 'APY', type: FieldTypes.Percentage, thousandSeparator: false, required: true },
        start_date: {
          label: 'Certificate Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Certificate Start Date cannot be greater than Certificate End Date')
        },
        end_date: {
          label: 'Certificate End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        current_deposit_value: {
          type: FieldTypes.Currency,
          calculate: 'CalculateCurrentDepositValue',
          dependencies: ['opening_deposit', 'start_date', 'interest_rate'],
          total: true
        },
        asset_class_settings: {
          type: FieldTypes.ListMap,
          options: 'AssetClasses',
          schema: (yup) =>
            yup.test('sum', 'The sum of percentages cannot be greater than 100%', (value) => {
              const sum = value ? value.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0;
              return sum <= 100;
            })
        }
      }
    },
    FixedAnnuity: {
      name: 'Fixed Annuity',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      fields: {
        custodian_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        annuity_type: { type: FieldTypes.Select, required: true, options: 'InvestmentAssetsAnnuityTypes' },
        initial_investment: { type: FieldTypes.Currency, required: true, total: true },
        yearly_contribution: { type: FieldTypes.Currency, total: true },
        income_election: { type: FieldTypes.Select, required: true, options: 'PayoutTypes' },
        interest_rate: { type: FieldTypes.Percentage, required: true },
        start_date: {
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Start Date cannot be greater than End Date')
        },
        end_date: {
          type: FieldTypes.Date,
          required: true,
          calculate: 'PlanningHorizonDate'
        },
        payout_start_date: {
          type: FieldTypes.Date,
          schema: (yup, { payout_end_date }) =>
            maxDateCompare(yup, payout_end_date, 'Payout Start Date cannot be greater than Payout End Date')
        },
        payout_end_date: {
          type: FieldTypes.Date,
          calculate: 'PlanningHorizonDate'
        },
        cap: { type: FieldTypes.Percentage },
        floor: { type: FieldTypes.Percentage },
        buffer: { type: FieldTypes.Percentage },
        participation_rate: { type: FieldTypes.Percentage },
        beneficiary_field: {
          type: FieldTypes.Select,
          label: 'Beneficiary',
          options: 'Members',
          dynamic: true
        },
        asset_class_settings: {
          type: FieldTypes.ListMap,
          options: 'AssetClasses',
          schema: (yup) =>
            yup.test('sum', 'The sum of percentages cannot be greater than 100%', (value) => {
              const sum = value ? value.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0;
              return sum <= 100;
            })
        }
      }
    },
    VariableAnnuity: {
      name: 'Variable Annuity',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      fields: {
        custodian_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        // annuity_type: { type: FieldTypes.Select, required: true, options: 'FinancialAnnuityTypes' },
        initial_investment: { type: FieldTypes.Currency, required: true, total: true },
        yearly_contribution: { type: FieldTypes.Currency, total: true },
        income_election: { type: FieldTypes.Select, options: 'PayoutTypes' },
        initial_interest_rate: { type: FieldTypes.Percentage, required: true },
        future_interest_rate: { type: FieldTypes.Percentage, required: true },
        start_date: {
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Start Date cannot be greater than End Date')
        },
        end_date: {
          type: FieldTypes.Date,
          required: true,
          calculate: 'PlanningHorizonDate'
        },
        payout_start_date: {
          type: FieldTypes.Date,
          schema: (yup, { payout_end_date }) =>
            maxDateCompare(yup, payout_end_date, 'Payout Start Date cannot be greater than Payout End Date')
        },
        payout_end_date: {
          type: FieldTypes.Date,
          calculate: 'PlanningHorizonDate'
        },
        cap: { type: FieldTypes.Percentage },
        floor: { type: FieldTypes.Percentage },
        buffer: { type: FieldTypes.Percentage },
        participation_rate: { type: FieldTypes.Percentage },
        beneficiary_field: {
          type: FieldTypes.Select,
          label: 'Beneficiary',
          options: 'Members',
          dynamic: true
        },
        asset_class_settings: {
          type: FieldTypes.ListMap,
          options: 'AssetClasses',
          schema: (yup) =>
            yup.test('sum', 'The sum of percentages cannot be greater than 100%', (value) => {
              const sum = value ? value.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0;
              return sum <= 100;
            })
        }
      }
    },
    RILA: {
      name: 'RILA',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      fields: {
        custodian_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        // annuity_type: { type: FieldTypes.Select, required: true, options: 'FinancialAnnuityTypes' },
        initial_investment: { type: FieldTypes.Currency, required: true, total: true },
        yearly_contribution: { type: FieldTypes.Currency, total: true },
        income_election: { type: FieldTypes.Select, options: 'PayoutTypes' },
        initial_interest_rate: { type: FieldTypes.Percentage, required: true },
        future_interest_rate: { type: FieldTypes.Percentage, required: true },
        start_date: {
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Start Date cannot be greater than End Date')
        },
        end_date: {
          type: FieldTypes.Date,
          required: true,
          calculate: 'PlanningHorizonDate'
        },
        payout_start_date: {
          type: FieldTypes.Date,
          schema: (yup, { payout_end_date }) =>
            maxDateCompare(yup, payout_end_date, 'Payout Start Date cannot be greater than Payout End Date')
        },
        payout_end_date: {
          type: FieldTypes.Date,
          calculate: 'PlanningHorizonDate'
        },
        cap: { type: FieldTypes.Percentage },
        floor: { type: FieldTypes.Percentage },
        buffer: { type: FieldTypes.Percentage },
        participation_rate: { type: FieldTypes.Percentage },
        beneficiary_field: {
          type: FieldTypes.Select,
          label: 'Beneficiary',
          options: 'Members',
          dynamic: true
        },
        asset_class_settings: {
          type: FieldTypes.ListMap,
          options: 'AssetClasses',
          schema: (yup) =>
            yup.test('sum', 'The sum of percentages cannot be greater than 100%', (value) => {
              const sum = value ? value.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0;
              return sum <= 100;
            })
        }
      }
    }
    // MoneyMarketAccount: {
    //   name: 'Money Market Account',
    //   defaultTitle: 'Account Description',
    //   multiple: true,
    //   asset: true,
    //   nebo: true,
    //   priority: true,
    //   fields: {
    //     bank_name: { type: FieldTypes.Text, required: true },
    //     account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
    //     account_balance: {
    //       type: FieldTypes.Currency,
    //       required: true,
    //       total: true
    //     },
    //     interest_rate: { type: FieldTypes.Percentage, required: true },
    //     asset_class_settings: {
    //       type: FieldTypes.ListMap,
    //       options: 'AssetClasses'
    //     }
    //   }
    // },
    // Commodities: {
    //   name: 'Commodities',
    //   defaultTitle: 'Asset Description',
    //   multiple: true,
    //   asset: true,
    //   nebo: true,
    //   priority: true,
    //   fields: {
    //     brokerage_account: {
    //       label: 'Taxable Account',
    //       type: FieldTypes.Select,
    //       dynamic: true,
    //       options: 'TaxableAndSavingsAccounts',
    //       info: 'In order to populate this field, please create a Taxable Account entry within the Financial Assets bucket.'
    //     },
    //     symbol: { label: 'ISIN / Ticker Symbol', type: FieldTypes.Text },
    //     fund_name: { type: FieldTypes.Text, required: true },
    //     growth_rate: { label: 'Override Growth Rate', type: FieldTypes.Percentage },
    //     shares: { label: 'Number of Shares', type: FieldTypes.Numeric, thousandSeparator: false, required: true },
    //     share_price: { label: 'Amount per Share', type: FieldTypes.Currency, required: true },
    //     total_value: {
    //       type: FieldTypes.Currency,
    //       calculate: 'CommonTotalAmount',
    //       dependencies: ['shares', 'share_price'],
    //       total: true
    //     },
    //     asset_class: { type: FieldTypes.Select, options: 'AssetClasses' }
    //   }
    // }
  },
  VariableIncome: {
    EarnedIncome: {
      name: 'Earned Income',
      defaultTitle: 'Salary',
      multiple: true,
      asset: true,
      fields: {
        // Taxable Account, Checking Account, Savings Account, Money Market Account, Corporate Pension, Social Security, Loan or Line of Credit
        // associated_account: {
        //   type: FieldTypes.Select,
        //   dynamic: true,
        //   options: 'AssociatedAccounts',
        //   info: 'In order to populate this field, please create a Taxable Account, Checking Account, Savings Account, Money Market Account, Corporate Pension, Social Security, Loan or Line of Credit entry within the Financial Assets bucket.'
        // },
        net_amount: { label: 'Net Amount (Yearly)', type: FieldTypes.Currency, required: true, total: true },
        start_date: {
          label: 'Income Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Income Start Date cannot be greater than Income End Date')
        },
        end_date: {
          label: 'Income End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        growth_rate: { type: FieldTypes.Percentage, required: true }
      }
    },
    RentalIncome: {
      name: 'Rental Income',
      defaultTitle: 'Rent',
      multiple: true,
      asset: true,
      fields: {
        net_amount: { label: 'Net Amount (Yearly)', type: FieldTypes.Currency, required: true, total: true },
        start_date: {
          label: 'Income Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Income Start Date cannot be greater than Income End Date')
        },
        end_date: {
          label: 'Income End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        growth_rate: { type: FieldTypes.Percentage, required: true }
      }
    },
    BusinessIncome: {
      name: 'Business Income',
      defaultTitle: 'Income Description',
      multiple: true,
      asset: true,
      fields: {
        net_amount: { label: 'Net Amount (Yearly)', type: FieldTypes.Currency, required: true, total: true },
        start_date: {
          label: 'Income Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Income Start Date cannot be greater than Income End Date')
        },
        end_date: {
          label: 'Income End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        growth_rate: { type: FieldTypes.Percentage, required: true }
      }
    },
    RoyaltyIncome: {
      name: 'Royalty Income',
      defaultTitle: 'Income Description',
      multiple: true,
      asset: true,
      fields: {
        net_amount: { label: 'Net Amount (Yearly)', type: FieldTypes.Currency, required: true, total: true },
        start_date: {
          label: 'Income Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Income Start Date cannot be greater than Income End Date')
        },
        end_date: {
          label: 'Income End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        growth_rate: { type: FieldTypes.Percentage, required: true }
      }
    },
    DividendIncome: {
      name: 'Dividend Income',
      defaultTitle: 'Salary',
      multiple: true,
      asset: true,
      fields: {
        net_amount: { label: 'Net Amount (Yearly)', type: FieldTypes.Currency, required: true, total: true },
        start_date: {
          label: 'Income Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Income Start Date cannot be greater than Income End Date')
        },
        end_date: {
          label: 'Income End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        growth_rate: { type: FieldTypes.Percentage }
      }
    },
    InterestIncome: {
      name: 'Interest Income',
      defaultTitle: 'Salary',
      multiple: true,
      asset: true,
      fields: {
        net_amount: { label: 'Net Amount (Yearly)', type: FieldTypes.Currency, required: true, total: true },
        start_date: {
          label: 'Income Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Income Start Date cannot be greater than Income End Date')
        },
        end_date: {
          label: 'Income End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        growth_rate: { type: FieldTypes.Percentage }
      }
    }
  },
  OutsideAssets: {
    PersonalProperty: {
      name: 'Personal Property',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        asset_value: { type: FieldTypes.Currency, required: true, total: true },
        asset_type: { type: FieldTypes.Select, options: 'AssetTypes' },
        growth_rate: { label: 'Asset Growth Rate', type: FieldTypes.Percentage, required: true }
      }
    },
    RealProperty: {
      name: 'Real Property',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      btnText: 'Calculate Property Value(s)',
      btnLink: 'http://zillow.com/',
      fields: {
        city: { label: 'Location City', type: FieldTypes.Text },
        state: { label: 'Location State', type: FieldTypes.Select, options: States },
        estate_value: { type: FieldTypes.Currency, required: true, total: true },
        growth_rate: { type: FieldTypes.Percentage, required: true }
      }
    },
    PrivateAssets: {
      name: 'Private Assets',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        // Taxable Account
        brokerage_account: {
          label: 'Taxable Account',
          type: FieldTypes.Select,
          dynamic: true,
          options: 'TaxableAccounts',
          info: 'In order to populate this field, please create a Taxable Account entry within the Financial Assets bucket.'
        },
        symbol: { label: 'ISIN / Ticker Symbol', type: FieldTypes.Text },
        fund_name: { type: FieldTypes.Text },
        growth_rate: { label: 'Override Growth Rate', type: FieldTypes.Percentage },
        shares: { label: 'Number of Shares', type: FieldTypes.Numeric, thousandSeparator: false },
        share_price: { label: 'Amount per Share', type: FieldTypes.Currency },
        total_value: {
          type: FieldTypes.Currency,
          calculate: 'CommonTotalAmount',
          dependencies: ['shares', 'share_price'],
          total: true
        },
        asset_class: { type: FieldTypes.Select, options: 'AssetClasses' }
      }
    },
    Vehicle: {
      name: 'Vehicle',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      btnText: 'Calculate Vehicle Value(s)',
      btnLink: 'http://kbb.com/',
      fields: {
        vin: { label: 'Vehicle VIN', type: FieldTypes.Text },
        year: { label: 'Vehicle Year', type: FieldTypes.Numeric, thousandSeparator: false },
        make: { label: 'Vehicle Make', type: FieldTypes.Text },
        model: { label: 'Vehicle Model', type: FieldTypes.Text },
        present_value: { type: FieldTypes.Currency, required: true, total: true },
        depreciation: { type: FieldTypes.Percentage, required: true },
        residual_value: { type: FieldTypes.Currency, required: true }
      }
    },
    Boat: {
      name: 'Boat',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        vin: { label: 'Vehicle VIN', type: FieldTypes.Text },
        year: { label: 'Vehicle Year', type: FieldTypes.Numeric, thousandSeparator: false },
        make: { label: 'Vehicle Make', type: FieldTypes.Text },
        model: { label: 'Vehicle Model', type: FieldTypes.Text },
        present_value: { type: FieldTypes.Currency, required: true, total: true },
        depreciation: { type: FieldTypes.Percentage, required: true },
        residual_value: { type: FieldTypes.Currency, required: true }
      }
    },
    RV: {
      name: 'RV',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        vin: { label: 'Vehicle VIN', type: FieldTypes.Text },
        year: { label: 'Vehicle Year', type: FieldTypes.Numeric, thousandSeparator: false },
        make: { label: 'Vehicle Make', type: FieldTypes.Text },
        model: { label: 'Vehicle Model', type: FieldTypes.Text },
        present_value: { type: FieldTypes.Currency, required: true, total: true },
        depreciation: { type: FieldTypes.Percentage, required: true },
        residual_value: { type: FieldTypes.Currency, required: true }
      }
    },
    BusinessInterest: {
      name: 'Business Interest',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      btnText: 'Business Valuation',
      btnLink: BUSINESS_VALUATIONS,
      fields: {
        present_value: { label: 'Present Business Valuation Amount', type: FieldTypes.Currency, required: true, total: true },
        growth_rate: { type: FieldTypes.Percentage, required: true },
        start_date: {
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Start Date cannot be greater than End Date')
        },
        end_date: { type: FieldTypes.Date, required: true, dependencies: ['start_date'] },
        ownership: { label: 'Ownership (%)', type: FieldTypes.Numeric }
      }
    },
    PermanentLifeInsurance: {
      name: 'Permanent Life Insurance',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      fields: {
        carrier_name: {
          type: FieldTypes.Text,
          required: true
        },
        link_to_life_risk: {
          label: 'Link to Life Risk',
          type: FieldTypes.Select,
          dynamic: true,
          options: 'LinkToLifeRisks',
          optionKey: 'link_to_long_term_care'
        },
        premium_amount: { type: FieldTypes.Currency, required: true },
        premium_frequency: { type: FieldTypes.Select, required: true, options: 'MQYFrequencies' },
        premium_start_date: {
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { premium_end_date }) =>
            maxDateCompare(yup, premium_end_date, 'Premium Start Date cannot be greater than Premium End Date')
        },
        premium_end_date: {
          type: FieldTypes.Date,
          required: true,
          keep: true,
          dependencies: ['premium_start_date'],
          activate: 'WhenLinkToLifeRisksIsNotSelect'
        },
        date_of_death: {
          type: FieldTypes.Date,
          required: true,
          activate: 'WhenLinkToLifeRisksIsSelect',
          calculate: 'DateOfDeath',
          dependencies: ['link_to_life_risk']
          // info: 'If you want to change this field, Please change from life event, Death Form.'
        },
        benefit_amount: {
          label: 'Benefit Amount (Face Value)',
          required: true,
          type: FieldTypes.Currency,
          dependencies: ['current_cash_value', 'withdrawal_value']
        },
        payout_frequency: { type: FieldTypes.Select, options: [{ label: 'One-Time', value: 101 }] },
        current_cash_value: {
          type: FieldTypes.Currency,
          total: true,
          schema: (yup, { benefit_amount, premium_start_date, premium_end_date, growth_rate }) => {
            if (premium_start_date && premium_end_date) {
              return yup.test('CurrentCashCalculation', 'Current Cash Value cannot be greater than Benefit Amount', (v) => {
                if (!v) {
                  return true;
                }
                const endYear = dayjs(premium_end_date, 'MM/DD/YYYY').year();
                const startYear = dayjs(premium_start_date, 'MM/DD/YYYY').year();
                const years = endYear - startYear;
                let currentValue = v;
                for (let year = 0; year <= years; year++) {
                  currentValue *= 1 + growth_rate / 100;
                }
                return benefit_amount >= currentValue;
              });
            }
            return yup.max(benefit_amount, 'Current Cash Value cannot be greater than Benefit Amount');
          }
        },
        growth_rate: { type: FieldTypes.Percentage },
        withdrawal_value: {
          type: FieldTypes.Currency,
          schema: (yup, { benefit_amount, current_cash_value, withdrawal_payout_frequency, payout_start_date, payout_end_date }) => {
            if (payout_start_date && payout_end_date) {
              return yup.test('withdrawalCalculation', (v, { createError }) => {
                if (!v) {
                  return true;
                }
                let value;
                // const startYear = dayjs(payout_start_date, 'MM/DD/YYYY').year();
                // const currentYear = dayjs().year();
                const monthsLeftInCurrentYear = 12 - dayjs(payout_start_date, 'MM/DD/YYYY').month();

                switch (withdrawal_payout_frequency) {
                  case 98:
                    value = monthsLeftInCurrentYear * v;
                    break;
                  case 99:
                    value = (monthsLeftInCurrentYear / 3) * v;
                    break;
                  case 100:
                  case 101:
                    value = v;
                    break;
                  default:
                    return true;
                }

                const isValid = current_cash_value >= value;

                if (!isValid) {
                  return createError({
                    message: 'Withdrawal Value cannot be greater than Current Cash Value'
                  });
                }

                // Validation for the benefit field
                let totalValue = 0;
                const startDate = dayjs(payout_start_date, 'MM/DD/YYYY');
                const endDate = dayjs(payout_end_date, 'MM/DD/YYYY');
                const monthsDiff = endDate.diff(startDate, 'month') + 1;
                const yearsDiff = endDate.year() - startDate.year() + 1;

                switch (withdrawal_payout_frequency) {
                  case 101:
                    totalValue = v;
                    break;
                  case 100:
                    totalValue = v * yearsDiff;
                    break;
                  case 99:
                    totalValue = v * (monthsDiff / 3);
                    break;
                  case 98:
                    totalValue = v * monthsDiff;
                    break;
                  default:
                    return true;
                }
                if (totalValue >= benefit_amount) {
                  return createError({
                    message: 'Withdrawal Value cannot be greater than Benefit Amount'
                  });
                }
                return true;
              });
            }

            return yup.max(current_cash_value, 'Withdrawal Value cannot be greater than Current Cash Value');
          }
        },
        withdrawal_payout_frequency: {
          label: 'Withdrawal Payout Frequency',
          required: true,
          type: FieldTypes.Select,
          options: 'MQAOFrequencies',
          dependencies: ['withdrawal_value']
        },
        // withdrawal_location: {
        //   type: FieldTypes.Select,
        //   dynamic: true,
        //   options: 'WithdrawalLocations',
        //   info: 'In order to populate this field, please create a category entry within one of the plans Asset or Liability buckets.'
        // },
        beneficiary_field: { type: FieldTypes.Select, label: 'Beneficiary', required: true, options: 'Members', dynamic: true },
        payout_start_date: {
          type: FieldTypes.Date,
          required: true,
          dependencies: ['withdrawal_value'],
          schema: (yup, { payout_end_date }) =>
            maxDateCompare(yup, payout_end_date, 'Payout Start Date cannot be greater than Payout End Date')
        },
        payout_end_date: { type: FieldTypes.Date, required: true, keep: true, dependencies: ['withdrawal_value', 'payout_start_date'] }
      }
    },
    OtherAssets: {
      name: 'Other Assets',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        start_date: {
          label: 'Asset Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Asset Start Date cannot be greater than Asset End Date')
        },
        end_date: {
          label: 'Asset End Date',
          required: true,
          type: FieldTypes.Date,
          dependencies: ['start_date']
        },
        asset_value: { type: FieldTypes.Currency, required: true, total: true },
        inflation: { type: FieldTypes.Percentage, required: true }
      }
    },
    MoneyMarketAccount: {
      name: 'Money Market Account',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        bank_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        interest_rate: { type: FieldTypes.Percentage, required: true }
      }
    },
    SavingsAccount: {
      name: 'Savings Account',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        bank_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true,
          schema: (yup, { account_limit }) => {
            if (account_limit) {
              return yup.max(account_limit, 'Account Balance cannot be more than Account Limit');
            }
          }
        },
        account_limit: {
          type: FieldTypes.Currency,
          dependencies: ['account_balance']
        },
        interest_rate: { type: FieldTypes.Percentage, required: true },
        account_type: { type: FieldTypes.Select, required: true, options: 'AccountTypes' }
      }
    },
    CheckingAccount: {
      name: 'Checking Account',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        bank_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true,
          schema: (yup, { account_limit }) => {
            if (account_limit) {
              return yup.max(account_limit, 'Account Balance cannot be more than Account Limit');
            }
          }
        },
        account_limit: {
          type: FieldTypes.Currency,
          dependencies: ['account_balance']
        }
      }
    },
    ShortTermCertificatesOfDeposit: {
      name: 'Short Term Certificates of Deposit',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        bank_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        opening_deposit: { type: FieldTypes.Currency, required: true },
        interest_payments: { type: FieldTypes.Select, required: true, options: 'MAMFrequencies' },
        interest_rate: { type: FieldTypes.Percentage, required: true },
        apy: { label: 'APY', type: FieldTypes.Percentage, thousandSeparator: false, required: true },
        start_date: {
          label: 'Certificate Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Certificate Start Date cannot be greater than Certificate End Date')
        },
        end_date: {
          label: 'Certificate End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        current_deposit_value: {
          type: FieldTypes.Currency,
          calculate: 'CalculateCurrentDepositValue',
          dependencies: ['opening_deposit', 'start_date', 'interest_rate'],
          total: true
        }
      }
    },
    Cash: {
      name: 'Cash',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        amount: { type: FieldTypes.Currency, required: true, total: true }
      }
    },
    Crypto: {
      name: 'Crypto',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      btnText: "Find Today's Crypto Price",
      btnLink: 'http://coinmarketcap.com/',
      fields: {
        brokerage_account: {
          label: 'Taxable Account',
          type: FieldTypes.Select,
          dynamic: true,
          options: 'TaxableAccounts',
          info: 'In order to populate this field, please create a Taxable Account entry within the Financial Assets bucket.'
        },
        symbol: { label: 'ISIN / Ticker Symbol', type: FieldTypes.Text },
        fund_name: { type: FieldTypes.Text, required: true },
        growth_rate: { label: 'Override Growth Rate', type: FieldTypes.Percentage },
        shares: { label: 'Number of Shares', type: FieldTypes.Numeric, thousandSeparator: false, required: true },
        share_price: { label: 'Amount per Share', type: FieldTypes.Currency, required: true },
        total_value: {
          type: FieldTypes.Currency,
          calculate: 'CommonTotalAmount',
          dependencies: ['shares', 'share_price'],
          total: true
        },
        asset_class: { type: FieldTypes.Select, options: 'AssetClasses' }
      }
    }
  },
  GuaranteedIncome: {
    CorporatePension: {
      name: 'Corporate Pension',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      fields: {
        company_name: { type: FieldTypes.Text },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        amount: { type: FieldTypes.Currency, required: true, total: true, dependencies: ['monthly_payment', 'lump_sum'] },
        payment_type: { type: FieldTypes.Select, required: true, options: 'PaymentTypes', dependencies: ['monthly_payment', 'lump_sum'] },
        monthly_payment: {
          label: 'Monthly Payment (If Applicable)',
          type: FieldTypes.Currency,
          schema: (yup, { payment_type, amount, lump_sum, start_date, end_date }) => {
            let months = start_date && end_date && dayjs(end_date, 'MM/DD/YYYY').diff(dayjs(start_date, 'MM/DD/YYYY'), 'month');
            if (!months || months === 0) {
              months = 1;
            }
            if (payment_type === PaymentTypes[2].value) {
              return yup.max((amount - lump_sum ?? 0) / months, 'Lump Sum / Monthly Payment calculated cannot be greater than Amount');
            }
            return yup.max(amount / months, 'Monthly Payment cannot be greater than Amount');
          }
        },
        lump_sum: {
          label: 'Lump Sum (If Applicable)',
          type: FieldTypes.Currency,
          schema: (yup, { amount }) => {
            return yup.max(amount, 'Lump Sum cannot be greater than Amount');
          },
          dependencies: ['monthly_payment']
        },
        start_date: {
          label: 'Payment Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Payment Start Date cannot be greater than Payment End Date'),
          dependencies: ['monthly_payment', 'lump_sum']
        },
        end_date: {
          label: 'Payment End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date', 'monthly_payment', 'lump_sum']
        },
        income_election: { type: FieldTypes.Select, options: 'PayoutTypes' },
        beneficiary_field: {
          type: FieldTypes.Select,
          label: 'Beneficiary (If Applicable)',
          options: 'Members',
          dynamic: true
        }
      }
    },
    SocialSecurity: {
      name: 'Social Security',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      btnText: 'Calculate Social Security',
      btnLink: 'https://www.ssa.gov/OACT/quickcalc/',
      fields: {
        retirement_age: {
          type: FieldTypes.Select,
          required: true,
          options: [
            { label: '62', value: 62 },
            { label: '67', value: 67 },
            { label: '70', value: 70 }
          ]
        },
        monthly_payment: { type: FieldTypes.Currency, required: true, total: true },
        inflation: { type: FieldTypes.Percentage, required: true },
        start_date: { type: FieldTypes.Date, required: true, calculate: 'SocialSecurityStartDate', dependencies: ['retirement_age'] }
      }
    },
    IncomeAnnuity: {
      name: 'Income Annuity',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      fields: {
        custodian_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        annuity_type: { type: FieldTypes.Select, required: true, options: 'GuaranteedIncomeAnnuityTypes' },
        initial_investment: { type: FieldTypes.Currency, required: true, total: true },
        yearly_contribution: { type: FieldTypes.Currency, total: true },
        income_election: { type: FieldTypes.Select, required: true, options: 'PayoutTypes' },
        interest_rate: { type: FieldTypes.Percentage, required: true },
        start_date: {
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Start Date cannot be greater than End Date')
        },
        end_date: {
          type: FieldTypes.Date,
          required: true,
          calculate: 'PlanningHorizonDate'
        },
        payout_start_date: {
          type: FieldTypes.Date,
          schema: (yup, { payout_end_date }) =>
            maxDateCompare(yup, payout_end_date, 'Payout Start Date cannot be greater than Payout End Date')
        },
        payout_end_date: {
          type: FieldTypes.Date,
          calculate: 'PlanningHorizonDate'
        },
        cap: { type: FieldTypes.Percentage },
        floor: { type: FieldTypes.Percentage },
        buffer: { type: FieldTypes.Percentage },
        participation_rate: { type: FieldTypes.Percentage },
        beneficiary_field: {
          type: FieldTypes.Select,
          label: 'Beneficiary',
          options: 'Members',
          dynamic: true
        }
      }
    },
    // FixedIncome: {
    //   name: 'Fixed Income',
    //   defaultTitle: 'Salary',
    //   multiple: true,
    //   asset: true,
    //   fields: {
    //     net_amount: { label: 'Net Amount (Yearly)', type: FieldTypes.Currency, required: true, total: true },
    //     start_date: {
    //       label: 'Income Start Date',
    //       type: FieldTypes.Date,
    //       required: true,
    //       schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Income Start Date cannot be greater than Income End Date')
    //     },
    //     end_date: {
    //       label: 'Income End Date',
    //       type: FieldTypes.Date,
    //       required: true,
    //       dependencies: ['start_date']
    //     },
    //     growth_rate: { type: FieldTypes.Percentage }
    //   }
    // },
    TaxableIncome: {
      name: 'Taxable Income',
      defaultTitle: 'Salary',
      multiple: true,
      asset: true,
      fields: {
        net_amount: { label: 'Net Amount (Yearly)', type: FieldTypes.Currency, required: true, total: true },
        start_date: {
          label: 'Income Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Income Start Date cannot be greater than Income End Date')
        },
        end_date: {
          label: 'Income End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        growth_rate: { type: FieldTypes.Percentage }
      }
    },
    TaxFreeIncome: {
      name: 'Tax-Free Income',
      defaultTitle: 'Salary',
      multiple: true,
      asset: true,
      fields: {
        net_amount: { label: 'Net Amount (Yearly)', type: FieldTypes.Currency, required: true, total: true },
        start_date: {
          label: 'Income Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Income Start Date cannot be greater than Income End Date')
        },
        end_date: {
          label: 'Income End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        growth_rate: { type: FieldTypes.Percentage }
      }
    }
  },
  Inheritance: {
    TrustDistribution: {
      name: 'Trust Distribution',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      fields: {
        // Taxable Account, Checking Account, Savings Account, Money Market Account, Corporate Pension, Social Security, Loan or Line of Credit
        associated_account: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'AssociatedAccounts',
          info: 'In order to populate this field, please create an account under one of the following categories: Taxable Account, Checking Account, Savings Account, Money Market Account, Corporate Pension, Social Security, Loan or Line of Credit.'
        },
        beneficiary_field: {
          type: FieldTypes.Select,
          label: 'Beneficiary',
          // required: true,
          options: 'Members',
          dynamic: true,
          activate: 'WhenLinkToLifeRisksIsSelect'
        },
        net_distribution_amount: { type: FieldTypes.Currency, required: true, total: true },
        amount_per: { type: FieldTypes.Select, required: true, options: 'MQYOPerFrequencies' },
        paid_every: { type: FieldTypes.Select, required: true, options: 'MAOFrequencies' },
        start_date: {
          label: 'Income Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Income Start Date cannot be greater than Income End Date')
        },
        end_date: {
          label: 'Income End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        growth_rate: { type: FieldTypes.Percentage, required: true }
      }
    },
    TaxableAccount: {
      name: 'Taxable Account',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        custodian_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        // account_location: { type: FieldTypes.Select, required: true, options: 'AccountLocations' },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        interest_rate: {
          type: FieldTypes.Percentage,
          required: true
        },
        // investment_vehicle: { type: FieldTypes.Select, required: true, options: 'InvestmentVehicles' },
        yearly_contribution: { type: FieldTypes.Currency },
        // withdrawal_date: {
        //   type: FieldTypes.Date,
        //   required: true
        // },
        management_fee: {
          label: 'Advisory Fee',
          type: FieldTypes.Percentage,
          required: true
        },
        investment_manager_1: { label: 'Investment Advisor (If not us)', type: FieldTypes.Text },
        asset_class_settings: {
          type: FieldTypes.ListMap,
          options: 'AssetClasses',
          schema: (yup) =>
            yup.test('sum', 'The sum of percentages cannot be greater than 100%', (value) => {
              const sum = value ? value.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0;
              return sum <= 100;
            })
        },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    RetirementAccount: {
      name: 'Retirement Account',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        custodian_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        interest_rate: {
          type: FieldTypes.Percentage,
          required: true
        },
        account_type: { type: FieldTypes.Select, required: true, options: 'RetirementAccountTypes' },
        yearly_contribution: { type: FieldTypes.Currency },
        withdrawal_date: {
          type: FieldTypes.Date,
          required: true
        },
        advisory_fee: {
          type: FieldTypes.Percentage,
          required: true
        },
        investment_advisor: { label: 'Investment Advisor (If not us)', type: FieldTypes.Text },
        asset_class_settings: {
          type: FieldTypes.ListMap,
          options: 'AssetClasses',
          schema: (yup) =>
            yup.test('sum', 'The sum of percentages cannot be greater than 100%', (value) => {
              const sum = value ? value.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0;
              return sum <= 100;
            })
        },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    Securities: {
      name: 'Securities',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      btnText: "Find Today's Stock Price",
      btnLink: 'http://finance.yahoo.com/',
      fields: {
        brokerage_account: {
          label: 'Taxable Account',
          type: FieldTypes.Select,
          dynamic: true,
          options: 'TaxableAndSavingsAccounts',
          info: 'In order to populate this field, please create a Taxable Account entry within the Financial Assets bucket.'
        },
        symbol: { label: 'ISIN / Ticker Symbol', type: FieldTypes.Text },
        fund_name: { type: FieldTypes.Text, required: true },
        growth_rate: { label: 'Override Growth Rate', type: FieldTypes.Percentage },
        shares: { label: 'Number of Shares', type: FieldTypes.Numeric, thousandSeparator: false, required: true },
        share_price: { label: 'Amount per Share', type: FieldTypes.Currency, required: true },
        total_value: {
          type: FieldTypes.Currency,
          calculate: 'CommonTotalAmount',
          dependencies: ['shares', 'share_price'],
          total: true
        },
        asset_class: { type: FieldTypes.Select, options: 'AssetClasses' },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    LongTermCertificatesOfDeposit: {
      name: 'Long Term Certificates of Deposit',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        bank_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        opening_deposit: { type: FieldTypes.Currency, required: true },
        interest_payments: { type: FieldTypes.Select, required: true, options: 'MAMFrequencies' },
        interest_rate: { type: FieldTypes.Percentage, required: true },
        apy: { label: 'APY', type: FieldTypes.Percentage, thousandSeparator: false, required: true },
        start_date: {
          label: 'Certificate Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Certificate Start Date cannot be greater than Certificate End Date')
        },
        end_date: {
          label: 'Certificate End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        current_deposit_value: {
          type: FieldTypes.Currency,
          calculate: 'CalculateCurrentDepositValue',
          dependencies: ['opening_deposit', 'start_date', 'interest_rate'],
          total: true
        }
      }
    },
    FixedAnnuity: {
      name: 'Fixed Annuity',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      fields: {
        custodian_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        annuity_type: { type: FieldTypes.Select, required: true, options: 'InvestmentAssetsAnnuityTypes' },
        initial_investment: { type: FieldTypes.Currency, required: true, total: true },
        yearly_contribution: { type: FieldTypes.Currency, total: true },
        income_election: { type: FieldTypes.Select, required: true, options: 'PayoutTypes' },
        interest_rate: { type: FieldTypes.Percentage, required: true },
        start_date: {
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Start Date cannot be greater than End Date')
        },
        end_date: {
          type: FieldTypes.Date,
          required: true,
          calculate: 'PlanningHorizonDate'
        },
        payout_start_date: {
          type: FieldTypes.Date,
          schema: (yup, { payout_end_date }) =>
            maxDateCompare(yup, payout_end_date, 'Payout Start Date cannot be greater than Payout End Date')
        },
        payout_end_date: {
          type: FieldTypes.Date,
          calculate: 'PlanningHorizonDate'
        },
        cap: { type: FieldTypes.Percentage },
        floor: { type: FieldTypes.Percentage },
        buffer: { type: FieldTypes.Percentage },
        participation_rate: { type: FieldTypes.Percentage },
        beneficiary_field: {
          type: FieldTypes.Select,
          label: 'Beneficiary',
          options: 'Members',
          dynamic: true
        }
      }
    },
    VariableAnnuity: {
      name: 'Variable Annuity',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      fields: {
        custodian_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        // annuity_type: { type: FieldTypes.Select, required: true, options: 'FinancialAnnuityTypes' },
        initial_investment: { type: FieldTypes.Currency, required: true, total: true },
        yearly_contribution: { type: FieldTypes.Currency, total: true },
        income_election: { type: FieldTypes.Select, options: 'PayoutTypes' },
        initial_interest_rate: { type: FieldTypes.Percentage, required: true },
        future_interest_rate: { type: FieldTypes.Percentage, required: true },
        start_date: {
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Start Date cannot be greater than End Date')
        },
        end_date: {
          type: FieldTypes.Date,
          required: true,
          calculate: 'PlanningHorizonDate'
        },
        payout_start_date: {
          type: FieldTypes.Date,
          schema: (yup, { payout_end_date }) =>
            maxDateCompare(yup, payout_end_date, 'Payout Start Date cannot be greater than Payout End Date')
        },
        payout_end_date: {
          type: FieldTypes.Date,
          calculate: 'PlanningHorizonDate'
        },
        cap: { type: FieldTypes.Percentage },
        floor: { type: FieldTypes.Percentage },
        buffer: { type: FieldTypes.Percentage },
        participation_rate: { type: FieldTypes.Percentage },
        beneficiary_field: {
          type: FieldTypes.Select,
          label: 'Beneficiary',
          options: 'Members',
          dynamic: true
        }
      }
    },
    RILA: {
      name: 'RILA',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      fields: {
        custodian_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        // annuity_type: { type: FieldTypes.Select, required: true, options: 'FinancialAnnuityTypes' },
        initial_investment: { type: FieldTypes.Currency, required: true, total: true },
        yearly_contribution: { type: FieldTypes.Currency, total: true },
        income_election: { type: FieldTypes.Select, options: 'PayoutTypes' },
        initial_interest_rate: { type: FieldTypes.Percentage, required: true },
        future_interest_rate: { type: FieldTypes.Percentage, required: true },
        start_date: {
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Start Date cannot be greater than End Date')
        },
        end_date: {
          type: FieldTypes.Date,
          required: true,
          calculate: 'PlanningHorizonDate'
        },
        payout_start_date: {
          type: FieldTypes.Date,
          schema: (yup, { payout_end_date }) =>
            maxDateCompare(yup, payout_end_date, 'Payout Start Date cannot be greater than Payout End Date')
        },
        payout_end_date: {
          type: FieldTypes.Date,
          calculate: 'PlanningHorizonDate'
        },
        cap: { type: FieldTypes.Percentage },
        floor: { type: FieldTypes.Percentage },
        buffer: { type: FieldTypes.Percentage },
        participation_rate: { type: FieldTypes.Percentage },
        beneficiary_field: {
          type: FieldTypes.Select,
          label: 'Beneficiary',
          options: 'Members',
          dynamic: true
        }
      }
    },
    MoneyMarketAccount: {
      name: 'Money Market Account',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        bank_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        interest_rate: { type: FieldTypes.Percentage, required: true },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    Commodities: {
      name: 'Commodities',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        brokerage_account: {
          label: 'Taxable Account',
          type: FieldTypes.Select,
          dynamic: true,
          options: 'TaxableAndSavingsAccounts',
          info: 'In order to populate this field, please create a Taxable Account entry within the Financial Assets bucket.'
        },
        symbol: { label: 'ISIN / Ticker Symbol', type: FieldTypes.Text },
        fund_name: { type: FieldTypes.Text, required: true },
        growth_rate: { label: 'Override Growth Rate', type: FieldTypes.Percentage },
        shares: { label: 'Number of Shares', type: FieldTypes.Numeric, thousandSeparator: false, required: true },
        share_price: { label: 'Amount per Share', type: FieldTypes.Currency, required: true },
        total_value: {
          type: FieldTypes.Currency,
          calculate: 'CommonTotalAmount',
          dependencies: ['shares', 'share_price'],
          total: true
        },
        asset_class: { type: FieldTypes.Select, options: 'AssetClasses' },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    PersonalProperty: {
      name: 'Personal Property',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        asset_value: { type: FieldTypes.Currency, required: true, total: true },
        asset_type: { type: FieldTypes.Select, options: 'AssetTypes' },
        growth_rate: { label: 'Asset Growth Rate', type: FieldTypes.Percentage, required: true },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    RealProperty: {
      name: 'Real Property',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      btnText: 'Calculate Property Value(s)',
      btnLink: 'http://zillow.com/',
      fields: {
        city: { label: 'Location City', type: FieldTypes.Text },
        state: { label: 'Location State', type: FieldTypes.Text },
        estate_value: { type: FieldTypes.Currency, required: true, total: true },
        growth_rate: { type: FieldTypes.Percentage, required: true },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    PrivateAssets: {
      name: 'Private Assets',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        // Taxable Account
        brokerage_account: {
          label: 'Taxable Account',
          type: FieldTypes.Select,
          dynamic: true,
          options: 'TaxableAccounts',
          info: 'In order to populate this field, please create a Taxable Account entry within the Financial Assets bucket.'
        },
        symbol: { label: 'ISIN / Ticker Symbol', type: FieldTypes.Text },
        fund_name: { type: FieldTypes.Text },
        growth_rate: { label: 'Override Growth Rate', type: FieldTypes.Percentage },
        shares: { label: 'Number of Shares', type: FieldTypes.Numeric, thousandSeparator: false },
        share_price: { label: 'Amount per Share', type: FieldTypes.Currency },
        total_value: {
          type: FieldTypes.Currency,
          calculate: 'CommonTotalAmount',
          dependencies: ['shares', 'share_price'],
          total: true
        },
        asset_class: { type: FieldTypes.Select, options: 'AssetClasses' },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    Vehicle: {
      name: 'Vehicle',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      btnText: 'Calculate Vehicle Value(s)',
      btnLink: 'http://kbb.com/',
      fields: {
        vin: { label: 'Vehicle VIN', type: FieldTypes.Text },
        year: { label: 'Vehicle Year', type: FieldTypes.Numeric, thousandSeparator: false },
        make: { label: 'Vehicle Make', type: FieldTypes.Text },
        model: { label: 'Vehicle Model', type: FieldTypes.Text },
        present_value: { type: FieldTypes.Currency, required: true, total: true },
        depreciation: { type: FieldTypes.Percentage, required: true },
        residual_value: { type: FieldTypes.Currency, required: true },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    Boat: {
      name: 'Boat',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        vin: { label: 'Vehicle VIN', type: FieldTypes.Text },
        year: { label: 'Vehicle Year', type: FieldTypes.Numeric, thousandSeparator: false },
        make: { label: 'Vehicle Make', type: FieldTypes.Text },
        model: { label: 'Vehicle Model', type: FieldTypes.Text },
        present_value: { type: FieldTypes.Currency, required: true, total: true },
        depreciation: { type: FieldTypes.Percentage, required: true },
        residual_value: { type: FieldTypes.Currency, required: true },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    RV: {
      name: 'RV',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        vin: { label: 'Vehicle VIN', type: FieldTypes.Text },
        year: { label: 'Vehicle Year', type: FieldTypes.Numeric, thousandSeparator: false },
        make: { label: 'Vehicle Make', type: FieldTypes.Text },
        model: { label: 'Vehicle Model', type: FieldTypes.Text },
        present_value: { type: FieldTypes.Currency, required: true, total: true },
        depreciation: { type: FieldTypes.Percentage, required: true },
        residual_value: { type: FieldTypes.Currency, required: true },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    BusinessInterest: {
      name: 'Business Interest',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      btnText: 'Business Valuation',
      btnLink: BUSINESS_VALUATIONS,
      fields: {
        present_value: { label: 'Present Business Valuation Amount', type: FieldTypes.Currency, required: true, total: true },
        growth_rate: { type: FieldTypes.Percentage, required: true },
        start_date: {
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Start Date cannot be greater than End Date')
        },
        end_date: { type: FieldTypes.Date, required: true, dependencies: ['start_date'] },
        ownership: { label: 'Ownership (%)', type: FieldTypes.Numeric }
      }
    },
    PermanentLifeInsurance: {
      name: 'Permanent Life Insurance',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      fields: {
        carrier_name: {
          type: FieldTypes.Text,
          required: true
        },
        link_to_life_risk: {
          label: 'Link to Life Risk',
          type: FieldTypes.Select,
          dynamic: true,
          options: 'LinkToLifeRisks',
          optionKey: 'link_to_long_term_care'
        },
        premium_amount: { type: FieldTypes.Currency, required: true },
        premium_frequency: { type: FieldTypes.Select, required: true, options: 'MQYFrequencies' },
        premium_start_date: {
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { premium_end_date }) =>
            maxDateCompare(yup, premium_end_date, 'Premium Start Date cannot be greater than Premium End Date')
        },
        premium_end_date: {
          type: FieldTypes.Date,
          required: true,
          keep: true,
          dependencies: ['premium_start_date'],
          activate: 'WhenLinkToLifeRisksIsNotSelect'
        },
        date_of_death: {
          type: FieldTypes.Date,
          required: true,
          activate: 'WhenLinkToLifeRisksIsSelect',
          calculate: 'DateOfDeath',
          dependencies: ['link_to_life_risk']
          // info: 'If you want to change this field, Please change from life event, Death Form.'
        },
        benefit_amount: {
          label: 'Benefit Amount (Face Value)',
          required: true,
          type: FieldTypes.Currency,
          dependencies: ['current_cash_value', 'withdrawal_value']
        },
        payout_frequency: { type: FieldTypes.Select, options: [{ label: 'One-Time', value: 101 }] },
        current_cash_value: {
          type: FieldTypes.Currency,
          total: true,
          schema: (yup, { benefit_amount, premium_start_date, premium_end_date, growth_rate }) => {
            if (premium_start_date && premium_end_date) {
              return yup.test('CurrentCashCalculation', 'Current Cash Value cannot be greater than Benefit Amount', (v) => {
                if (!v) {
                  return true;
                }
                const endYear = dayjs(premium_end_date, 'MM/DD/YYYY').year();
                const startYear = dayjs(premium_start_date, 'MM/DD/YYYY').year();
                const years = endYear - startYear;
                let currentValue = v;
                for (let year = 0; year <= years; year++) {
                  currentValue *= 1 + growth_rate / 100;
                }
                return benefit_amount >= currentValue;
              });
            }
            return yup.max(benefit_amount, 'Current Cash Value cannot be greater than Benefit Amount');
          }
        },
        growth_rate: { type: FieldTypes.Percentage },
        withdrawal_value: {
          type: FieldTypes.Currency,
          schema: (yup, { benefit_amount, current_cash_value, withdrawal_payout_frequency, payout_start_date, payout_end_date }) => {
            if (payout_start_date && payout_end_date) {
              return yup.test('withdrawalCalculation', (v, { createError }) => {
                if (!v) {
                  return true;
                }
                let value;
                const monthsLeftInCurrentYear = 12 - dayjs(payout_start_date, 'MM/DD/YYYY').month();
                switch (withdrawal_payout_frequency) {
                  case 98:
                    value = monthsLeftInCurrentYear * v;
                    break;
                  case 99:
                    value = (monthsLeftInCurrentYear / 3) * v;
                    break;
                  case 100:
                  case 101:
                    value = v;
                    break;
                  default:
                    return true;
                }

                const isValid = current_cash_value >= value;

                if (!isValid) {
                  return createError({
                    message: 'Withdrawal Value cannot be greater than Current Cash Value'
                  });
                }

                // Validation for the benefit field
                let totalValue = 0;
                const startDate = dayjs(payout_start_date, 'MM/DD/YYYY');
                const endDate = dayjs(payout_end_date, 'MM/DD/YYYY');
                const monthsDiff = endDate.diff(startDate, 'month') + 1;
                const yearsDiff = endDate.year() - startDate.year() + 1;

                switch (withdrawal_payout_frequency) {
                  case 101:
                    totalValue = v;
                    break;
                  case 100:
                    totalValue = v * yearsDiff;
                    break;
                  case 99:
                    totalValue = v * (monthsDiff / 3);
                    break;
                  case 98:
                    totalValue = v * monthsDiff;
                    break;
                  default:
                    return true;
                }
                if (totalValue >= benefit_amount) {
                  return createError({
                    message: 'Withdrawal Value cannot be greater than Benefit Amount'
                  });
                }
                return true;
              });
            }

            return yup.max(current_cash_value, 'Withdrawal Value cannot be greater than Current Cash Value');
          }
        },
        // withdrawal_value: {
        //   type: FieldTypes.Currency,
        //   schema: (yup, { current_cash_value, withdrawal_payout_frequency, payout_start_date, payout_end_date }) => {
        //     if (payout_start_date && payout_end_date) {
        //       return yup.test('withdrawalCalculation', 'Withdrawal Value cannot be greater than Current Cash Value', (v) => {
        //         if (!v) {
        //           return true;
        //         }
        //         let value;
        //         const startYear = dayjs(payout_start_date, 'MM/DD/YYYY').year();
        //         const currentYear = dayjs().year();
        //         const monthsLeftInCurrentYear = startYear === currentYear ? 12 - dayjs(payout_start_date, 'MM/DD/YYYY').month() - 1 : 12;

        //         switch (withdrawal_payout_frequency) {
        //           case 98:
        //             value = monthsLeftInCurrentYear * v;
        //             break;
        //           case 99:
        //             value = (monthsLeftInCurrentYear / 3) * v;
        //             break;
        //           case 100:
        //           case 101:
        //             value = v;
        //             break;
        //         }
        //         return current_cash_value >= value;
        //       });
        //     }
        //     return yup.max(current_cash_value, 'Withdrawal Value cannot be greater than Current Cash Value');
        //   }
        // },
        withdrawal_payout_frequency: {
          label: 'Withdrawal Payout Frequency',
          required: true,
          type: FieldTypes.Select,
          options: 'MQAOFrequencies',
          dependencies: ['withdrawal_value']
        },
        // withdrawal_location: {
        //   type: FieldTypes.Select,
        //   dynamic: true,
        //   options: 'WithdrawalLocations',
        //   info: 'In order to populate this field, please create a category entry within one of the plans Asset or Liability buckets.'
        // },
        beneficiary_field: { type: FieldTypes.Select, label: 'Beneficiary', required: true, options: 'Members', dynamic: true },
        payout_start_date: {
          type: FieldTypes.Date,
          required: true,
          dependencies: ['withdrawal_value'],
          schema: (yup, { payout_end_date }) =>
            maxDateCompare(yup, payout_end_date, 'Payout Start Date cannot be greater than Payout End Date')
        },
        payout_end_date: { type: FieldTypes.Date, required: true, keep: true, dependencies: ['withdrawal_value', 'payout_start_date'] }
      }
    },
    OtherAssets: {
      name: 'Other Assets',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        start_date: {
          label: 'Asset Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Asset Start Date cannot be greater than Asset End Date')
        },
        end_date: {
          label: 'Asset End Date',
          required: true,
          type: FieldTypes.Date,
          dependencies: ['start_date']
        },
        asset_value: { type: FieldTypes.Currency, required: true, total: true },
        inflation: { type: FieldTypes.Percentage, required: true }
      }
    },
    SavingsAccount: {
      name: 'Savings Account',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        bank_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true,
          schema: (yup, { account_limit }) => {
            if (account_limit) {
              return yup.max(account_limit, 'Account Balance cannot be more than Account Limit');
            }
          }
        },
        account_limit: {
          type: FieldTypes.Currency,
          dependencies: ['account_balance']
        },
        interest_rate: { type: FieldTypes.Percentage, required: true },
        account_type: { type: FieldTypes.Select, required: true, options: 'AccountTypes' },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    CheckingAccount: {
      name: 'Checking Account',
      defaultTitle: 'Account Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        bank_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true,
          schema: (yup, { account_limit }) => {
            if (account_limit) {
              return yup.max(account_limit, 'Account Balance cannot be more than Account Limit');
            }
          }
        },
        account_limit: {
          type: FieldTypes.Currency,
          dependencies: ['account_balance']
        },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    ShortTermCertificatesOfDeposit: {
      name: 'Short Term Certificates of Deposit',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        bank_name: { type: FieldTypes.Text, required: true },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        opening_deposit: { type: FieldTypes.Currency, required: true },
        interest_payments: { type: FieldTypes.Select, required: true, options: 'MAMFrequencies' },
        interest_rate: { type: FieldTypes.Percentage, required: true },
        apy: { label: 'APY', type: FieldTypes.Percentage, thousandSeparator: false, required: true },
        start_date: {
          label: 'Certificate Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Certificate Start Date cannot be greater than Certificate End Date')
        },
        end_date: {
          label: 'Certificate End Date',
          type: FieldTypes.Date,
          required: true,
          dependencies: ['start_date']
        },
        current_deposit_value: {
          type: FieldTypes.Currency,
          calculate: 'CalculateCurrentDepositValue',
          dependencies: ['opening_deposit', 'start_date', 'interest_rate'],
          total: true
        }
      }
    },
    Cash: {
      name: 'Cash',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      fields: {
        amount: { type: FieldTypes.Currency, required: true, total: true },
        start_date: {
          type: FieldTypes.Date
        }
      }
    },
    Crypto: {
      name: 'Crypto',
      defaultTitle: 'Asset Description',
      multiple: true,
      asset: true,
      nebo: true,
      priority: true,
      btnText: "Find Today's Crypto Price",
      btnLink: 'http://coinmarketcap.com/',
      fields: {
        brokerage_account: {
          label: 'Taxable Account',
          type: FieldTypes.Select,
          dynamic: true,
          options: 'TaxableAccounts',
          info: 'In order to populate this field, please create a Taxable Account entry within the Financial Assets bucket.'
        },
        symbol: { label: 'ISIN / Ticker Symbol', type: FieldTypes.Text },
        fund_name: { type: FieldTypes.Text, required: true },
        growth_rate: { label: 'Override Growth Rate', type: FieldTypes.Percentage },
        shares: { label: 'Number of Shares', type: FieldTypes.Numeric, thousandSeparator: false, required: true },
        share_price: { label: 'Amount per Share', type: FieldTypes.Currency, required: true },
        total_value: {
          type: FieldTypes.Currency,
          calculate: 'CommonTotalAmount',
          dependencies: ['shares', 'share_price'],
          total: true
        },
        asset_class: { type: FieldTypes.Select, options: 'AssetClasses' },
        start_date: {
          type: FieldTypes.Date
        }
      }
    }
  },
  CurrentExpenses: {
    CreditCard: {
      name: 'Credit Card',
      defaultTitle: 'Account Description',
      multiple: true,
      fields: {
        bank_name: {
          type: FieldTypes.Text,
          required: true
        },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true,
          schema: (yup, { account_limit }) => {
            if (account_limit) {
              return yup.max(account_limit, 'Account Balance cannot be more than Account Limit');
            }
          }
        },
        payment_account: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'PaymentAccounts',
          info: 'In order to populate this field, please create an entry within Financial Assets for a Checking, Savings or Money Market Account.'
        },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        account_limit: {
          type: FieldTypes.Currency,
          dependencies: ['account_balance']
        }
      }
    },
    Loan: {
      name: 'Loan',
      defaultTitle: 'Account Description',
      multiple: true,
      modal: true,
      btnText: 'Find Loan Description Link',
      btnLink: 'http://finance.yahoo.com/',
      fields: {
        bank_name: {
          type: FieldTypes.Text,
          required: true
        },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        payment_account: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'PaymentAccounts',
          info: 'In order to populate this field, please create an entry within Financial Assets for a Checking, Savings or Money Market Account.'
        },
        collateral: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'Collaterals',
          required: true,
          info: 'In order to populate this field, please create a category entry within one of the plans Asset Category buckets.'
        },
        monthly_payment: {
          type: FieldTypes.Currency,
          required: true
        },
        interest_rate: {
          type: FieldTypes.Percentage,
          required: true,
          info: 'The annual percentage of the loan amount that the bank charges for borrowing the loan.'
        },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true,
          schema: (yup, { account_limit }) => {
            if (account_limit) {
              return yup.max(account_limit, 'Account Balance cannot be more than Account Limit');
            }
          }
        },
        account_limit: {
          type: FieldTypes.Currency,
          dependencies: ['account_balance']
        },
        remaining_term: {
          label: 'Remaining Term (In Years)',
          type: FieldTypes.Numeric,
          thousandSeparator: false,
          calculate: 'LoanRemainingTerm',
          dependencies: ['monthly_payment', 'interest_rate', 'account_balance']
        }
      }
    },
    LineOfCredit: {
      name: 'Line of Credit',
      defaultTitle: 'Account Description',
      multiple: true,
      fields: {
        bank_name: {
          type: FieldTypes.Text,
          required: true
        },
        account_number: { hidden: true, type: FieldTypes.Numeric, thousandSeparator: false },
        payment_account: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'PaymentAccounts',
          info: 'In order to populate this field, please create an entry within Financial Assets for a Checking, Savings or Money Market Account.'
        },
        drawdown_facility: {
          type: FieldTypes.Select,
          options: 'DrawdownFacilities',
          required: true
        },
        account_balance: {
          type: FieldTypes.Currency,
          required: true,
          total: true,
          schema: (yup, { account_limit }) => {
            if (account_limit) {
              return yup.max(account_limit, 'Account Balance cannot be more than Account Limit');
            }
          }
        },
        account_limit: {
          type: FieldTypes.Currency,
          required: true,
          dependencies: ['account_balance']
        },
        interest_rate: {
          type: FieldTypes.Percentage,
          required: true
        },
        variable_or_fixed: {
          label: 'Variable or Fixed Rate',
          type: FieldTypes.Select,
          required: true,
          options: 'VariableOrFixedRates'
        },
        minimum_payment: {
          type: FieldTypes.Currency,
          required: true
        },
        repayment_schedule: {
          type: FieldTypes.Select,
          required: true,
          options: 'RepaymentSchedules'
        },
        current_fees: {
          type: FieldTypes.Percentage,
          required: true
        },
        end_date: { label: 'Amortization End Date', type: FieldTypes.Date, required: true }
      }
    },
    OtherLiability: {
      name: 'Other Liability',
      defaultTitle: 'Account Description',
      multiple: true,
      fields: {
        amount: { label: 'Liability Amount', type: FieldTypes.Currency, required: true, total: true },
        payment_account: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'PaymentAccounts',
          info: 'In order to populate this field, please create an entry within Financial Assets for a Checking, Savings or Money Market Account.'
        },
        recurrence: { label: 'Liability Recurrence', type: FieldTypes.Select, required: true, options: 'MQAOFrequencies' },
        inflation: { label: 'Expense Growth + Inflation', type: FieldTypes.Percentage, required: true },
        start_date: {
          label: 'Liability Start Date',
          type: FieldTypes.Date,
          required: true,
          schema: (yup, { end_date }) => maxDateCompare(yup, end_date, 'Liability Start Date cannot be greater than Liability End Date')
        },
        end_date: {
          label: 'Liability End Date',
          type: FieldTypes.Date,
          required: true,
          activate: 'WhenRecurrenceIsNotOneTime',
          dependencies: ['start_date']
        }
      }
    },
    // CurrentFixedExpense: {
    //   name: 'Current Fixed Expense',
    //   defaultTitle: 'Account Description',
    //   multiple: true,
    //   fields: {
    //     amount: { label: 'Liability Amount', type: FieldTypes.Currency, required: true, total: true },
    //     payment_account: {
    //       type: FieldTypes.Select,
    //       dynamic: true,
    //       options: 'PaymentAccounts',
    //       info: 'In order to populate this field, please create an entry within Financial Assets for a Checking, Savings or Money Market Account.'
    //     },
    //     recurrence: { label: 'Liability Recurrence', type: FieldTypes.Select, required: true, options: 'MQAOFrequencies' },
    //     inflation: { type: FieldTypes.Percentage, required: true },
    //     start_date: { label: 'Expense Start Date', type: FieldTypes.Date, required: true },
    //     end_date: {
    //       label: 'Expense End Date',
    //       type: FieldTypes.Date,
    //       required: true,
    //       activate: 'WhenRecurrenceIsNotOneTime'
    //     }
    //   }
    // },
    CurrentExpense: {
      name: 'Current Expense',
      defaultTitle: 'Account Description',
      multiple: true,
      fields: {
        amount: { label: 'Liability Amount', type: FieldTypes.Currency, required: true, total: true },
        payment_account: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'PaymentAccounts',
          info: 'In order to populate this field, please create an entry within Financial Assets for a Checking, Savings or Money Market Account.'
        },
        recurrence: { label: 'Liability Recurrence', type: FieldTypes.Select, required: true, options: 'MQAOFrequencies' },
        inflation: { label: 'Expense Growth + Inflation', type: FieldTypes.Percentage, required: true },
        start_date: { label: 'Expense Start Date', type: FieldTypes.Date, required: true },
        end_date: {
          label: 'Expense End Date',
          type: FieldTypes.Date,
          activate: 'WhenRecurrenceIsNotOneTime',
          required: true
        }
      }
    }
  },
  RetirementExpenses: {
    RetirementExpenseRatio: {
      name: 'Retirement Expense Ratio',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        // start_of_retirement_expenses: { type: FieldTypes.Currency, disabled: true, total: true },
        payment_account: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'PaymentAccounts',
          info: 'In order to populate this field, please create an entry within Financial Assets for a Checking, Savings or Money Market Account.'
        },
        amount: { type: FieldTypes.Currency },
        inflation: { label: 'Expense Growth + Inflation', type: FieldTypes.Percentage, required: true },
        start_date: {
          label: 'Expense Start Date',
          type: FieldTypes.Date,
          required: true,
          calculate: 'RetirementExpenseStartDate'
        },
        end_date: {
          label: 'Expense End Date',
          type: FieldTypes.Date,
          calculate: 'RetirementExpenseEndDate'
        }
      }
    },
    AdditionalRetirementExpense: {
      name: 'Additional Retirement Expense',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        amount: { label: 'Expense Amount', type: FieldTypes.Currency, required: true, total: true },
        payment_account: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'PaymentAccounts',
          info: 'In order to populate this field, please create an entry within Financial Assets for a Checking, Savings or Money Market Account.'
        },
        recurrence: { label: 'Liability Recurrence', type: FieldTypes.Select, required: true, options: 'MQAOFrequencies' },
        inflation: { label: 'Expense Growth + Inflation', type: FieldTypes.Percentage, required: true },
        start_date: { label: 'Expense Start Date', type: FieldTypes.Date, required: true },
        end_date: {
          label: 'Expense End Date',
          type: FieldTypes.Date,
          activate: 'WhenRecurrenceIsNotOneTime',
          required: true
        }
      }
    }
  },
  AspirationalExpenses: {
    AdvancedEducation: {
      name: 'Advanced Education',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        estimated_cost: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        payment_frequency: {
          type: FieldTypes.Select,
          required: true,
          options: 'MQAOFrequencies'
        },
        start_date: { type: FieldTypes.MonthAndYear, required: true },
        end_date: { type: FieldTypes.MonthAndYear, required: true },
        funding_source: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'FundingSources'
        },
        priority_level: {
          type: FieldTypes.Select,
          required: true,
          options: 'PriorityLevels'
        },
        institution_type: {
          type: FieldTypes.Select,
          options: 'InstitutionTypes'
        },
        degree_course_type: { type: FieldTypes.Select, options: 'CourseTypes' }
      }
    },
    Vacation: {
      name: 'Vacation',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        estimated_cost: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        payment_frequency: {
          type: FieldTypes.Select,
          required: true,
          options: 'MQAOFrequencies'
        },
        destination: {
          type: FieldTypes.Select,
          options: 'Destinations'
        },
        duration: { label: 'Expected Duration (In Weeks)', type: FieldTypes.Numeric, required: true, thousandSeparator: false },
        start_date: { type: FieldTypes.MonthAndYear, required: true },
        end_date: {
          type: FieldTypes.MonthAndYear,
          required: true,
          calculate: 'VacationEndDate',
          dependencies: ['start_date', 'duration']
        },
        funding_source: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'FundingSources'
        },
        priority_level: {
          type: FieldTypes.Select,
          required: true,
          options: 'PriorityLevels'
        }
      }
    },
    Hobby: {
      name: 'Hobby',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        estimated_cost: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        payment_frequency: {
          type: FieldTypes.Select,
          required: true,
          options: 'MQAOFrequencies'
        },
        start_date: { type: FieldTypes.MonthAndYear, required: true },
        end_date: { type: FieldTypes.MonthAndYear, required: true },
        priority_level: { type: FieldTypes.Select, required: true, options: 'PriorityLevels' }
      }
    },
    RealEstate: {
      name: 'Real Estate',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        estimated_cost: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        payment_frequency: {
          type: FieldTypes.Select,
          required: true,
          options: 'MQAOFrequencies'
        },
        start_date: { type: FieldTypes.MonthAndYear, required: true },
        end_date: { type: FieldTypes.MonthAndYear, required: true },
        funding_source: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'FundingSources'
        },
        priority_level: {
          type: FieldTypes.Select,
          required: true,
          options: 'PriorityLevels'
        },
        property_type: {
          type: FieldTypes.Select,
          options: 'PropertyTypes'
        },
        property_city: { type: FieldTypes.Text },
        property_state: { type: FieldTypes.Text },
        maintenance_costs: {
          label: 'Estimated Yearly Maintenance Costs',
          type: FieldTypes.Currency,
          total: true
        },
        inflation: { label: 'Yearly Costs Inflation', type: FieldTypes.Percentage },
        expected_sell_date: { type: FieldTypes.MonthAndYear, required: true, activate: 'WhenPaymentFrequencyIsOneTime' }
      }
    },
    Other: {
      name: 'Other',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        expense_amount: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        payment_account: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'PaymentAccounts',
          info: 'In order to populate this field, please create an entry within Financial Assets for a Checking, Savings or Money Market Account.'
        },
        recurrence: { label: 'Liability Recurrence', type: FieldTypes.Select, required: true, options: 'MQAOFrequencies' },
        inflation: {
          label: 'Expense Growth + Inflation',
          type: FieldTypes.Percentage
        },
        start_date: { label: 'Expense Start Date', type: FieldTypes.Date, required: true },
        end_date: {
          label: 'Expense End Date',
          type: FieldTypes.Date,
          activate: 'WhenRecurrenceIsNotOneTime',
          required: true
        }
      }
    },
    Boat: {
      name: 'Boat',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        estimated_cost: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        payment_frequency: {
          type: FieldTypes.Select,
          required: true,
          options: 'MQAOFrequencies'
        },
        start_date: { type: FieldTypes.MonthAndYear, required: true },
        end_date: { type: FieldTypes.MonthAndYear, required: true },
        funding_source: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'FundingSources'
        },
        priority_level: {
          type: FieldTypes.Select,
          required: true,
          options: 'PriorityLevels'
        },
        new_or_used: {
          type: FieldTypes.Select,
          options: 'NewOrUsed'
        },
        year: {
          type: FieldTypes.Numeric,
          thousandSeparator: false
        },
        make: {
          type: FieldTypes.Text
        },
        model: {
          type: FieldTypes.Text
        },
        maintenance_costs: {
          label: 'Estimated Yearly Maintenance, Docking, Insurance Costs',
          type: FieldTypes.Currency,
          total: true
        },
        inflation: { label: 'Yearly Costs Inflation', type: FieldTypes.Percentage },
        expected_sell_date: { type: FieldTypes.MonthAndYear, required: true, activate: 'WhenPaymentFrequencyIsOneTime' }
      }
    },
    Vehicle: {
      name: 'Vehicle',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        estimated_cost: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        payment_frequency: {
          type: FieldTypes.Select,
          required: true,
          options: 'MQAOFrequencies'
        },
        start_date: { type: FieldTypes.MonthAndYear, required: true },
        end_date: { type: FieldTypes.MonthAndYear, required: true },
        funding_source: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'FundingSources'
        },
        priority_level: {
          type: FieldTypes.Select,
          required: true,
          options: 'PriorityLevels'
        },
        new_or_used: {
          type: FieldTypes.Select,
          options: 'NewOrUsed'
        },
        vehicle_type: {
          type: FieldTypes.Select,
          options: 'VehicleTypes'
        },
        year: {
          type: FieldTypes.Numeric,
          thousandSeparator: false
        },
        make: {
          type: FieldTypes.Text
        },
        model: {
          type: FieldTypes.Text
        },
        maintenance_costs: {
          label: 'Estimated Yearly Maintenance, Insurance Costs',
          type: FieldTypes.Currency,
          total: true
        },
        inflation: { label: 'Yearly Costs Inflation', type: FieldTypes.Percentage },
        expected_sell_date: { type: FieldTypes.MonthAndYear, required: true, activate: 'WhenPaymentFrequencyIsOneTime' }
      }
    },
    Philanthropy: {
      name: 'Philanthropy',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        estimated_cost: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        payment_frequency: {
          type: FieldTypes.Select,
          required: true,
          options: 'MQAOFrequencies'
        },
        start_date: { type: FieldTypes.MonthAndYear, required: true },
        end_date: { type: FieldTypes.MonthAndYear, required: true },
        funding_source: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'FundingSources'
        },
        priority_level: {
          type: FieldTypes.Select,
          required: true,
          options: 'PriorityLevels'
        },
        recipient_type: {
          type: FieldTypes.Select,
          options: 'RecipientTypes'
        },
        non_profit: { label: 'Non-Profit', type: FieldTypes.Select, options: 'YesNo' }
      }
    },
    HomeRenovations: {
      name: 'Home Renovations',
      defaultTitle: 'Asset Description',
      multiple: true,
      nebo: true,
      fields: {
        estimated_cost: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        payment_frequency: {
          type: FieldTypes.Select,
          required: true,
          options: 'MQAOFrequencies'
        },
        start_date: { type: FieldTypes.MonthAndYear, required: true },
        end_date: { type: FieldTypes.MonthAndYear, required: true },
        funding_source: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'FundingSources'
        },
        priority_level: {
          type: FieldTypes.Select,
          required: true,
          options: 'PriorityLevels'
        },
        property_value_increase: {
          label: 'Expected Property Value Increase',
          type: FieldTypes.Currency
        },
        linked_property: {
          type: FieldTypes.Select,
          dynamic: true,
          options: 'LinkedProperties',
          info: 'In order to populate this field, please create an entry within Outside Assets for a Real Property.'
        }
      }
    }
  },
  HealthcareExpenses: {
    InsuranceProvider: {
      name: 'Insurance Provider',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        policy_number: { type: FieldTypes.Numeric, thousandSeparator: false },
        amount: {
          label: 'Monthly Premium Amount',
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        deductible: { type: FieldTypes.Currency, required: true },
        co_pay: { label: 'Co-Pay', type: FieldTypes.Currency, required: true },
        max_out_of_pocket: {
          label: 'Max Out of Pocket',
          type: FieldTypes.Currency,
          required: true,
          schema: (yup, { deductible }) => {
            if (deductible) {
              return yup.min(deductible, 'Max Out of Pocket cannot be less than Deductible');
            }
          }
        },
        funding_source: { type: FieldTypes.Select, dynamic: true, options: 'FundingSources' },
        start_date: { label: 'Policy Start Date', type: FieldTypes.MonthAndYear, required: true },
        end_date: { label: 'Policy End Date', type: FieldTypes.MonthAndYear, required: true }
      }
    },
    FixedCostMedical: {
      name: 'Fixed Cost Medical',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        estimated_cost: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        payment_frequency: {
          type: FieldTypes.Select,
          required: true,
          options: 'MQAOFrequencies'
        },
        start_date: { type: FieldTypes.MonthAndYear, required: true },
        end_date: { type: FieldTypes.MonthAndYear, required: true },
        insurance_provider: { type: FieldTypes.Select, dynamic: true, options: 'InsuranceProviders' },
        medical_type: { type: FieldTypes.Select, options: 'MedicalTypes', required: true },
        tax_deductible: { type: FieldTypes.Select, options: 'YesNo', required: true },
        visit_frequency: { label: 'Visit Frequency (Monthly)', type: FieldTypes.Numeric, thousandSeparator: false },
        insurance_costs: { type: FieldTypes.MultiSelect, options: 'InsuranceCosts' }
      }
    },
    VariableCostMedical: {
      name: 'Variable Cost Medical',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        estimated_cost: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        payment_frequency: {
          type: FieldTypes.Select,
          required: true,
          options: 'MQAOFrequencies'
        },
        start_date: { type: FieldTypes.MonthAndYear, required: true },
        end_date: { type: FieldTypes.MonthAndYear, required: true },
        insurance_provider: { type: FieldTypes.Select, dynamic: true, options: 'InsuranceProviders' },
        medical_type: { type: FieldTypes.Select, options: 'MedicalTypes', required: true },
        tax_deductible: { type: FieldTypes.Select, options: 'YesNo', required: true },
        visit_frequency: { label: 'Visit Frequency (Monthly)', type: FieldTypes.Numeric, thousandSeparator: false },
        insurance_costs: { type: FieldTypes.MultiSelect, options: 'InsuranceCosts' },
        growth_rate: { label: 'Cost Growth Rate', type: FieldTypes.Percentage, required: false }
      }
    },
    LongTermCare: {
      name: 'Long Term Care',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        carrier_name: {
          type: FieldTypes.Text,
          required: true
        },
        life_risk: {
          label: 'Link to Life Risk',
          type: FieldTypes.MultiSelect,
          dynamic: true,
          options: 'LifeEvents',
          optionKey: 'link_to_long_term_care'
        },
        premium_amount: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        },
        premium_frequency: { type: FieldTypes.Select, required: true, options: 'MQYFrequencies' },
        premium_start_date: { type: FieldTypes.Date, required: true },
        premium_end_date: { type: FieldTypes.Date, required: true },
        benefit_amount: { label: 'Benefit Amount (Face Value)', type: FieldTypes.Currency, required: true },
        payout_frequency: { type: FieldTypes.Select, required: true, options: 'MQAOFrequencies' },
        payout_start_date: { type: FieldTypes.Date, required: true },
        payout_end_date: { type: FieldTypes.Date, required: true, activate: 'WhenPayoutFrequencyIsNotOneTime' },
        beneficiary_field: {
          type: FieldTypes.Select,
          label: 'Beneficiary',
          options: 'Members',
          dynamic: true
        }
      }
    }
  },
  TerminalWealth: {
    TerminalWealthAmount: {
      name: 'Terminal Wealth Amount',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        terminal_wealth_amount: {
          type: FieldTypes.Currency,
          required: true,
          total: true
        }
      }
    }
  },
  LifeEvents: {
    LongTermCare: {
      name: 'Long Term Care',
      defaultTitle: 'Asset Description',
      multiple: true,
      fields: {
        start_date: { type: FieldTypes.Date, required: true },
        end_date: { type: FieldTypes.Date, required: true },
        ltc_expense: {
          label: 'LTC Expense',
          type: FieldTypes.Currency,
          required: true
        },
        ltc_expense_recuurrence: {
          type: FieldTypes.Select,
          label: 'LTC Expense Recurrence',
          required: true,
          options: [
            { label: 'Monthly', value: 17 },
            { label: 'Yearly', value: 19 }
          ]
        },
        ltc_growth_rate: {
          label: 'LTC Growth Rate',
          type: FieldTypes.Percentage,
          required: true
        },
        link_to_long_term_care: {
          type: FieldTypes.Select,
          label: 'Link to Asset',
          dynamic: true,
          options: 'LongTermCares'
        }
      }
    },
    Disability: {
      name: 'Disability',
      defaultTitle: 'Disability Description',
      multiple: true,
      fields: {
        start_date: { type: FieldTypes.Date, required: true },
        end_date: { type: FieldTypes.Date, required: true },
        disability_income_severity: {
          type: FieldTypes.Select,
          required: true,
          options: [
            { label: 'Critical 100%', value: 100 },
            { label: 'Severe 75%', value: 75 },
            { label: 'Limited 50%', value: 50 },
            { label: 'Minor 25%', value: 25 }
          ]
        },
        disability_expense: {
          type: FieldTypes.Currency,
          required: true
        },
        disability_expense_recurrence: { type: FieldTypes.Select, required: true, options: 'MQYFrequencies' },
        disability_growth_rate: {
          type: FieldTypes.Percentage,
          required: true
        },
        link_to_long_term_care: {
          type: FieldTypes.Select,
          label: 'Link to Asset',
          dynamic: true,
          options: 'LongTermCares'
        }
      }
    },
    Death: {
      name: 'Death',
      defaultTitle: 'Death Description',
      multiple: true,
      activeSingle: true,
      activeSingleMessage: 'Only one death event can be active at a time',
      fields: {
        date_of_death: {
          type: FieldTypes.Date,
          required: true
        },
        funeral_expense: {
          type: FieldTypes.Currency,
          required: true
        },
        funeral_expense_frequency: { type: FieldTypes.Select, required: true, options: 'MQAOFrequencies' },
        hospital_expense: {
          type: FieldTypes.Currency
        },
        hospital_expense_recurrence: { type: FieldTypes.Select, options: 'MQAOFrequencies' },
        link_to_long_term_care: {
          type: FieldTypes.Select,
          label: 'Link to Asset',
          dynamic: true,
          options: 'DeathLongTermCares'
        }
      }
    }
  }
};
